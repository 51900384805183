import React from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import TetrisIcon from '../../icons/TetrisIcon';
import MainParticipant from '../MainParticipant/MainParticipant';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // padding: '2em',
      overflowY: 'auto',
      background: 'rgb(79, 83, 85)',
      gridArea: '1 / 2 / 1 / 3',
      width: '100%',
      zIndex: 5,
      [theme.breakpoints.down('sm')]: {
        gridArea: '2 / 1 / 3 / 3',
        overflowY: 'initial',
        overflowX: 'auto',
        display: 'flex',
        padding: `${theme.sidebarMobilePadding}px`,
      },
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flex: '1',
      [theme.breakpoints.down(750)]: {
        flexDirection: 'column',
      },
    },
    textContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      flexDirection: 'column',
      // position: 'absolute',
      textAlign: 'center',
      minHeight: ' 36vh',
      // [theme.breakpoints.down(750)]: {
      //  top:'43px',
      //  height: '87vh',
      // }
      [theme.breakpoints.down(750)]: {
        // top:'43px',
        // height: '87vh',
      },
    },
    upperText: {
      fontSize: '28px',
      padding: '0px',
      margin: '0px',
      [theme.breakpoints.down(750)]: {
        fontSize: '21px',
        fontWeight: 'bolder',
      },
      [theme.breakpoints.down(250)]: {
        fontSize: '14px',
        fontWeight: 'bolder',
      },
    },
    restText1: {
      fontSize: '14px',
      padding: '0px',
      margin: '0px',
    },
    restText2: {
      fontSize: '14px',
      padding: '0px',
      margin: '0px',
      [theme.breakpoints.down(750)]: {
        display: 'none',
      },
    },
    belowText: {
      display: 'none',
      [theme.breakpoints.down(750)]: {
        display: 'block',
        margin: '0',
        padding: '0',
      },
    },
    gameTrigger: {
      fill: '#4284f3',
      stroke: '#232325',
      textAlign: 'center',
      opacity: '0.25',
      cursor: 'pointer',
      paddingTop: '5px',
      display: 'none',
      [theme.breakpoints.down(750)]: {
        display: 'block',
      },
    },
    participantIdentity: {
      color: '#919192',
      background: '#323132',
      fontSize: '18px',
      minWidth: '60px',
      textAlign: 'center',
      height: '35px',
      padding: '5px 5px',
      borderRadius: '10px',
      position: 'absolute',
      top: '2%',
      left: '2%',
      zIndex: 9999,
      [theme.breakpoints.down(750)]: {
        height: '25px',
        top: '3%',
        padding: ' 0px 5px',
      },
    },
    abcd: {
      position: 'relative',
    },
    youtubeTrigger: {
      textAlign: 'center',
      '& .MuiSvgIcon-root': {
        fill: '#4284f3',
        fontSize: '6.5rem',
        opacity: '0.25',
        cursor: 'pointer',
      },
      display: 'none',

      [theme.breakpoints.down(750)]: {
        display: 'block',
      },
    },
    upperText1: {
      fontSize: '28px',
      padding: '0px',
      margin: '0px',
      [theme.breakpoints.down(750)]: {
        display: 'none',
      },
    },
    belowText1: {
      display: 'none',
      margin: '0',
      padding: '0',
      [theme.breakpoints.down(750)]: {
        fontSize: '21px',
        fontWeight: 'bolder',
        display: 'block',
      },
      [theme.breakpoints.down(250)]: {
        fontSize: '14px',
        fontWeight: 'bolder',
        display: 'block',
      },
    },
  })
);
export const NoParticpantText = ({
  handleTetrisIcon,
  handleActiveParticipant,
  handlePlayYoutube,
  waitingParticipantsLength,
}: {
  handleTetrisIcon?: any;
  handleActiveParticipant?: any;
  handlePlayYoutube?: any;
  waitingParticipantsLength?: any;
}) => {
  const classes = useStyles();
  handleActiveParticipant(0);
  return (
    <div className={classes.textContainer}>
      {waitingParticipantsLength === 0 ? (
        <>
          <p className={classes.upperText}>No other participants...yet!</p>
          <p className={classes.upperText1}>Invite someone using the invite button or QR code to your left.</p>
          <p className={classes.belowText1}>Invite someone using the invite button or QR code below!</p>
        </>
      ) : (
        <>
          <p className={classes.upperText}>Welcome to the meeting!</p>
          <p className={classes.upperText}>Press the “Admit” button by a participant’s name to get started.</p>
        </>
      )}
      <p className={classes.restText1}>Want to have some fun while you wait?</p>
      <p className={classes.restText2}>Click the YouTube icon or T to your left!</p>
      <p className={classes.belowText}>Click the YouTube icon or T below!</p>
      <div className={classes.youtubeTrigger} onClick={handlePlayYoutube}>
        <YouTubeIcon />
      </div>
      <div className={classes.gameTrigger} onClick={handleTetrisIcon}>
        <TetrisIcon />
      </div>
    </div>
  );
};
export default function ParticipantList({
  handleTetrisIcon,
  handleActiveParticipant,
  isSharingScreen,
  handlePlayYoutube,
  waitingParticipantsLength,
}: {
  handleTetrisIcon?: any;
  handleActiveParticipant?: any;
  isSharingScreen?: any;
  handlePlayYoutube?: any;
  waitingParticipantsLength?: any;
}) {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();

  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  if (participants?.length === 0) {
    return (
      <NoParticpantText
        handleTetrisIcon={handleTetrisIcon}
        handlePlayYoutube={handlePlayYoutube}
        handleActiveParticipant={handleActiveParticipant}
        waitingParticipantsLength={waitingParticipantsLength}
      />
    );
  } else {
    if (handleActiveParticipant()) handleActiveParticipant(participants?.length);
  }
  if (screenShareParticipant && !isSharingScreen) return <MainParticipant />;
  else
    return (
      <aside
        className={clsx(classes.container, {
          [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
        })}
      >
        <div className={classes.scrollContainer}>
          {/* <Participant participant={localParticipant} isLocalParticipant={true} /> */}
          {participants &&
            participants.map(participant => {
              const isSelected = participant === selectedParticipant;
              const hideParticipant =
                participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
              return (
                <React.Fragment key={participant.sid}>
                  <>
                    {/* <div className={classes.participantIdentity}>{participant.identity}</div> */}
                    <Participant
                      key={participant.sid}
                      participant={participant}
                      isSelected={participant === selectedParticipant}
                      onClick={() => setSelectedParticipant(participant)}
                      hideParticipant={hideParticipant}
                    />
                  </>
                </React.Fragment>
              );
            })}
        </div>
      </aside>
    );
}

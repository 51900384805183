// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  // apiKey: 'AIzaSyDoNxe2_5X_hE2uYVsdlSFZ9A1f4bvLEr4',
  // authDomain: 'employeecommunications-f8023.firebaseapp.com',
  // databaseURL: 'https://employeecommunications-f8023.firebaseio.com',
  // projectId: 'employeecommunications-f8023',
  // storageBucket: 'employeecommunications-f8023.appspot.com',
  // messagingSenderId: '735323509526',
  // appId: '1:735323509526:web:c84d6e28cb0dfa200d7637',

};

export default firebaseConfig;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ChevronUp from '../../../icons/ChevronUp';

const useStyles = makeStyles((theme: Theme) => ({
  rButton: {
    zIndex: 9999,
    [theme.breakpoints.down(750)]: {
      width: '99%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  rightBtn: {
    position: 'absolute',
    right: '2%',
    bottom: '2%',
    zIndex: 9999,
    background: '#232325',
    borderRadius: '10px',
    [theme.breakpoints.down(750)]: {
      right: '1%',
      bottom: '1%',
      width: '99%',
      left: '1%',
      position: 'sticky',
      // position: 'relative',
    },
  },
  rightBtn1: {
    position: 'absolute',
    right: '2%',
    bottom: '2%',
    zIndex: 9999,
    background: '#232325',
    borderRadius: '10px',
    [theme.breakpoints.down(750)]: {
      right: '1%',
      bottom: '1%',
      width: '99%',
      left: '1%',
      position: 'initial',
      margin: '0 auto',
      // position: 'relative',
    },
  },
  rightBtn2: {
    position: 'absolute',
    right: '2%',
    bottom: '2%',
    zIndex: 9999,
    background: '#232325',
    borderRadius: '10px',
    [theme.breakpoints.down(750)]: {
      right: '1%',
      bottom: '1%',
      width: '99%',
      left: '1%',
      // position: 'sticky',
      // position: 'relative',
    },
  },
  rIconSpace: {
    paddingLeft: '20px',
    paddingTop: '5px',
    [theme.breakpoints.down(750)]: {
      paddingLeft: '5px',
      paddingTop: '2px',
    },
  },
  unreadChats: {
    background: '#4284f3',
  },
  readChats: {
    background: '#323132',
  },
  button: {
    height: '35px',
    minWidth: '80px',
    border: 'none',
    outline: 'none',
    fontSize: '18px',
    borderRadius: '10px',
    padding: '5px 10px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 9999,
  },
}));

export default function ToggleRightButton({
  totalChatsLength,
  chatsLength,
  handleRightBtn,
  showWaitingRoom,
  posRightBtn,
}: {
  totalChatsLength?: any;
  chatsLength?: any;
  handleRightBtn?: any;
  showWaitingRoom?: boolean;
  posRightBtn?: any;
}) {
  const classes = useStyles();
  return (
    <div
      className={
        showWaitingRoom
          ? // ? `${classes.rightBtn} ${classes.rightBtn1}`
            classes.rightBtn2
          : posRightBtn
          ? classes.rightBtn1
          : classes.rightBtn
      }
    >
      <button
        className={
          totalChatsLength - chatsLength > 0
            ? `${classes.rButton} ${classes.unreadChats} ${classes.button}`
            : `${classes.rButton} ${classes.button}  ${classes.readChats}`
        }
        onClick={handleRightBtn}
      >
        {totalChatsLength - chatsLength > 0 ? `Chat (${totalChatsLength - chatsLength})` : 'Chat'}
        <span className={classes.rIconSpace}>
          {/* <KeyboardArrowUpIcon /> */}
          <ChevronUp />
        </span>
      </button>
    </div>
  );
}

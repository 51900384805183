import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { countryCodes, countries } from '../InviteDialog/countryData';
import { db, functions } from '../../firebase/db.config';
import firebase from 'firebase';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactFlagsSelect from 'react-flags-select';
import logo from '../../icons/logo.png';
import { axiosConfig, frontURL } from '../../axiosBaseURL';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firebaseHelper } from '../../firebaseHelper';
import { isValid } from '../InviteDialog/isValid';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: '#232325',
    height: '100%',
    width: '100%',
    fontFamily: 'Arial',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    width: '55%',
    textAlign: 'center',
    margin: '5% auto',
    flex: '1',
    [theme.breakpoints.down(600)]: {
      width: '75%',
    },
    [theme.breakpoints.down(300)]: {
      width: '85%',
    },
  },
  container3: {
    display: 'flex',
    justifyContent: 'center',
    margin: '40px 0px',
    '& .MuiButton-root': {
      padding: '0px 10px',
      margin: '0px 20px',
      height: '25px',
    },
  },
  headerText: {
    color: 'white',
    fontVariant: 'small-caps',
    fontSize: '20px',
  },
  text: {
    color: 'white',
    margin: '40px auto',
    width: '65%',
    [theme.breakpoints.down(100)]: {
      width: '85%',
    },
    [theme.breakpoints.down(750)]: {
      width: '85%',
    },
  },
  cancelBtn: {
    color: 'white',
    background: '#323132',
    cursor: 'pointer',
  },
  input: {
    padding: '10px 5px 10px 10px',
    outline: 'none',
    border: '1px solid #aaa',
    borderRadius: '5px',
    background: '#232325',
    color: 'white',
    width: '100% !important',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    // '&:-webkit-autofill': {
    //   WebkitBoxShadow: "0 0 0 1000px white inset"
    // }
  },
  input1: {
    padding: '10px 5px 10px 95px',
    // '&:-webkit-autofill': {
    //   WebkitBoxShadow: "0 0 0 1000px #919192 inset"
    // }
  },
  inputBox: {
    flex: '1',
    margin: '0px 5px',
    [theme.breakpoints.down(750)]: {
      margin: '10px 5px',
    },
  },
  inputBox1: {
    flex: '1',
    margin: '0px 5px',
    display: 'flex',
    [theme.breakpoints.down(750)]: {
      display: 'block',
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px',
    alignItems: 'center',
    [theme.breakpoints.down(750)]: {
      flexDirection: 'column',
      alignItems: 'inherit',
    },
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '-20px',
    padding: '0px 10px',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  errorText: {
    color: 'red',
    width: '50% !important',
  },
  errorDuplicateText: {
    color: 'red',
    marginBottom: '0px',
  },
  errorText1: {
    color: 'red',
    display: 'none',
    width: '50% !important',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      textAlign: 'initial',
      marginTop: '5px',
      marginBottom: '0px',
      width: '100% !important',
    },
  },
  addBtn: {
    float: 'right',
    cursor: 'pointer',
    color: 'white',
  },
  removeBtn: {
    color: 'crimson',
    cursor: 'pointer',
    [theme.breakpoints.down(750)]: {
      margin: '7px 0px',
      textAlign: 'center',
    },
  },
  countryCodeSelector: {
    position: 'absolute',
    '& .ReactFlagsSelect-module_selectBtn__19wW7': {
      color: '#919192',
      width: '80px',
      maxWidth: '80px',
      padding: '5px',
      borderRight: '1px solid #aaa',
      borderLeft: '1px solid #aaa',
      borderTop: '1px solid #aaa',
      borderBottom: '0px',
      borderRadius: '5px 0px 0px 5px',
    },
    '& .ReactFlagsSelect-module_selectValue__152eS': {
      padding: '0px',
    },
    '& .ReactFlagsSelect-module_selectOptions__3LNBJ': {
      background: '#232325',
    },
    '& .ReactFlagsSelect-module_label__27pw9': {
      color: '#919192',
    },
    '& .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM:hover': {
      backgroundColor: 'grey',
      '& .ReactFlagsSelect-module_label__27pw9': {
        color: 'white',
      },
    },
  },
  inviteBtn: {
    color: 'white',
    background: '#4284f3',
    cursor: 'pointer',
  },
  mainLogo: {
    width: '400px',
    paddingRight: '10px',
    paddingBottom: '10px',
    [theme.breakpoints.down(750)]: {
      width: '45%',
      margin: 'auto ',
      marginBottom: '20px',
    },
    [theme.breakpoints.down(350)]: {
      width: '65%',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
toast.configure();
function SendInvitation() {
  const classes = useStyles();
  const { URLRoomName, URLRoomType } = useParams();
  const [inputList, setInputList] = useState([{ name: '', contactInfo: '' }]);
  const [errorIndex, setErrorIndex] = useState<number>();
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<number>();
  const [contactError, setContactError] = useState<number>();
  const [selected, setSelected] = useState(['US']);
  const letterNumber = /^(?=.*[0-9])[- +()0-9]+$/;
  const emailValidatorRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const url = window.location.origin + `/room/${URLRoomName}/${URLRoomType}`;

  const addDataToFirebase = async (name?: any, contactInfo?: any) => {
    if (URLRoomName) {
      var idDocAdded = await firebaseHelper(URLRoomName);
      var hnm;
      await db
        .collection('roomDetails')
        .doc(idDocAdded)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            hnm = doc.data()?.hostName;
          }
        });
      const invitationObj = {
        inviteeContactInfo: contactInfo,
        inviteeName: name,
        invitedBy: hnm || '',
        timeStamp: firebase.firestore.Timestamp.now(),
      };
      await db
        .collection('roomDetails')
        .doc(idDocAdded)
        .update({
          invites: firebase.firestore.FieldValue.arrayUnion(invitationObj),
        });
    }
  };
  const handleInputChange = (a: any, b: any, e: any, index: any) => {
    const list: any = [...inputList];
    const { name, value } = e.target;
    list[index][name] = value;

    setInputList(list);
  };
  useEffect(() => {
    inputList.forEach((item, index) => {
      if (index > 0) {
        if (
          inputList[index].contactInfo.trim().length !== 0 &&
          inputList[index - 1].contactInfo.trim().length !== 0 &&
          inputList[index].contactInfo === inputList[index - 1].contactInfo
        ) {
          setDuplicateError(true);
        } else setDuplicateError(false);
      }
      if (!item.contactInfo.match(letterNumber) && item.contactInfo.trim().length !== 0) {
        if (!emailValidatorRegex.test(item.contactInfo)) {
          setEmailError(index);
          setErrorIndex(index);
        } else {
          setEmailError(100);
        }
      } else {
        setEmailError(100);
      }
    });
  }, [inputList]);
  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    const list1 = [...selected];
    list1.splice(index, 1);
    setSelected(list1);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { name: '', contactInfo: '' }]);
    setSelected([...selected, 'US']);
  };
  const handleCountryChange = (e: any, index: any) => {
    const list: any = [...selected];
    list[index] = e;
    setSelected(list);
  };
  const handleInviteBtn = () => {
    let breakloop = false;

    inputList.forEach((item, index) => {
      if (!item.contactInfo.match(letterNumber) && item.contactInfo.trim().length !== 0) {
        if (!emailValidatorRegex.test(item.contactInfo)) {
          setEmailError(index);
          setErrorIndex(index);
          breakloop = true;
        } else {
          setEmailError(100);
          breakloop = false;
        }
      } else {
        setEmailError(100);
        breakloop = false;
      }
    });
    selected.forEach((item, index) => {
      if (!selected[index] && inputList[index].contactInfo.match(letterNumber)) {
        setErrorIndex(index);
        breakloop = true;
      }
      if (selected[index] && inputList[index].contactInfo.match(letterNumber)) {
        if (!isValid(selected[index], inputList[index].contactInfo)) {
          setErrorIndex(index);
          setContactError(index);
          breakloop = true;
        } else {
          setErrorIndex(100);
          setContactError(100);
          breakloop = false;
        }
      }
    });
    inputList.forEach((item, index) => {
      if (item.name.trim().length === 0 || item.contactInfo.trim().length === 0) {
        setErrorIndex(index);
        breakloop = true;
      }
    });
    if (breakloop === false && duplicateError === false) {
      inputList.forEach((item, index) => {
        if (item.contactInfo.match(letterNumber)) {
          var abc = '';
          Object.keys(countryCodes).forEach((item: any, i) => {
            if (item === selected[index]) {
              abc = Object.values(countryCodes)[i];
            }
          });
          var x = abc.split('+')[1] + item.contactInfo;
          var result = x.replace(/[- )(]/g, '');
          var item = {
            name: item.name,
            contactInfo: result,
          };
          // axiosConfig.post('/sendSMS', { item, url }).catch(function(error) {
          //   console.log('error ----', error);
          // });
          const telehealthsendsms = functions.httpsCallable('telehealthsendsms');
          telehealthsendsms({ item, url })
            .then(res => {
              if (breakloop === false && duplicateError === false && res.data.status === 'SMS sent') {
                toast.dark('Invitation(s) sent!', {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setInputList([{ name: '', contactInfo: '' }]);
                setSelected(['US']);
              }
              // else {
              //   toast.error('Invitation(s) sent!', {
              //     position: 'top-center',
              //     autoClose: 5000,
              //     hideProgressBar: true,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //   });
              // }
            })
            .catch(err => err);
          addDataToFirebase(item.name, result);
        } else {
          // axiosConfig.post('/sendEmail', { item, url }).catch(function(error) {
          //   console.log('error ----', error);
          // });
          const telehealthsendemail = functions.httpsCallable('telehealthsendemail');
          telehealthsendemail({ item, url })
            .then(res => {
              if (breakloop === false && duplicateError === false && res.data.status === 'Email sent') {
                toast.dark('Invitation(s) sent!', {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setInputList([{ name: '', contactInfo: '' }]);
                setSelected(['US']);
              }
              // else {
              //   toast.error('Invitation(s) sent!', {
              //     position: 'top-center',
              //     autoClose: 5000,
              //     hideProgressBar: true,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //   });
              // }
            })
            .catch(err => err);
          addDataToFirebase(item.name, item.contactInfo);
        }
      });
    }
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerText}>Invite Users to My TeleShare Session</div>
        <div className={classes.text}>
          By inviting people into this session, I am authorizing them to participate in this conversation about my
          Protected Health Information
        </div>
        {duplicateError && <p className={classes.errorDuplicateText}>You have already invited this user</p>}
        {inputList.map((x, i) => {
          return (
            <div key={i}>
              <div className={classes.inputContainer}>
                <div className={classes.inputBox}>
                  <input
                    name="name"
                    placeholder="Name"
                    value={x.name}
                    className={classes.input}
                    maxLength={16}
                    onChange={e => handleInputChange('', '', e, i)}
                  />
                  {errorIndex === i && (
                    <p className={classes.errorText1}>{inputList[i].name.trim().length === 0 && 'Required'}</p>
                  )}
                </div>
                <div className={classes.inputBox1}>
                  <ReactFlagsSelect
                    className={classes.countryCodeSelector}
                    countries={countries}
                    customLabels={countryCodes}
                    selected={selected[i]}
                    fullWidth={false}
                    placeholder="Code"
                    onSelect={code => handleCountryChange(code, i)}
                  />
                  <input
                    className={`${classes.input} ${classes.input1}`}
                    name="contactInfo"
                    placeholder="SMS # or Email"
                    value={x.contactInfo}
                    onChange={e => handleInputChange('', '', e, i)}
                  />
                  {errorIndex === i && (
                    <p className={classes.errorText1}>
                      {inputList[i].contactInfo.trim().length === 0
                        ? 'Valid SMS # or Email Required'
                        : emailError === i
                        ? 'Invalid email address'
                        : contactError === i
                        ? 'Invalid SMS address'
                        : ''}
                    </p>
                  )}
                </div>
                {inputList.length !== 1 && (
                  <span className={classes.removeBtn} onClick={() => handleRemoveClick(i)}>
                    <DeleteIcon />
                  </span>
                )}
              </div>
              {errorIndex === i && (
                <div className={classes.errorContainer}>
                  <p className={classes.errorText}>{inputList[i].name.trim().length === 0 && 'Required'}</p>
                  <p className={classes.errorText}>
                    {inputList[i].contactInfo.trim().length === 0
                      ? 'Valid SMS # or Email Required'
                      : emailError === i
                      ? 'Invalid email address'
                      : contactError === i
                      ? 'Invalid SMS address'
                      : ''}
                  </p>
                </div>
              )}
            </div>
          );
        })}
        {inputList.length < 2 && (
          <span className={classes.addBtn} onClick={handleAddClick}>
            <AddIcon />
          </span>
        )}
        <div className={classes.container3}>
          <Button
            className={classes.inviteBtn}
            onClick={handleInviteBtn}
            variant="contained"
            autoFocus
            disableFocusRipple={true}
          >
            Invite
          </Button>
        </div>
      </div>

      <div className={classes.logoContainer}>
        <img src={logo} alt="main logo" className={classes.mainLogo} />
      </div>
    </div>
  );
}

export default SendInvitation;

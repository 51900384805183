import Axios from 'axios';
import { axiosConfig } from '../../axiosBaseURL';
import { db } from './../../firebase/db.config';

export const updateParticipants = ({ participants, roomId }) => {
  const roomRef = db.collection('roomDetails');
  try {
    if (!roomId || typeof roomId !== 'string' || roomId === '') throw new Error('Invalid roomId');

    roomRef.doc(roomId).update({
      waitingParticipantsArray: participants,
    });
    return;
  } catch (error) {
    console.log('ERROR : removeParticipants', error);
    return;
  }
};

import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { COLLECTIONS } from '../../constants';
import { db } from '../../firebase/db.config';

type RoomDetailsType = {
  roomId: string | null;
  waitingParticipantsArray: Array<{
    participantName: string;
    timeStamp: string;
  }>;
  activeParticipantsArray: Array<string>;
};

export default function useRoomDetails() {
  const { roomName = '' } = useParams<any>();
  const [state, setState] = useState<RoomDetailsType>({
    roomId: null,
    waitingParticipantsArray: [],
    activeParticipantsArray: [],
  });

  useEffect(() => {
    const unsubscribeRoomColection = setRoom();
    return () => {
      if (unsubscribeRoomColection) unsubscribeRoomColection();
    };
  }, [roomName]);

  // getting and setting document details of the specific room corresponding to the roomName in  the router
  const setRoom = useCallback((): (() => void) => {
    if (roomName && roomName !== '') {
      return db
        .collection(COLLECTIONS.ROOM_DETAILS)
        .where('roomName', '==', roomName)
        .onSnapshot(snapShot => {
          if (snapShot.docs[0].exists) {
            const room = snapShot.docs[0].data();
            setState(({ ...o }) => {
              o.roomId = snapShot.docs[0].id;
              o.waitingParticipantsArray = room?.waitingParticipantsArray || [];
              o.activeParticipantsArray = room?.activeParticipantsArray || [];
              return o;
            });
          }
        });
    }
    return () => {};
  }, [roomName]);

  return {
    ...state,
  };
}

import React, { useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import logo from '../../../icons/logo.png';
import icon from '../../../icons/icon.png';
import VideoIcon from '../../../icons/video2';
import Microphone from '../../../icons/microphone';
import { useAudioOutputDevices } from '../../../hooks/deviceHooks/deviceHooks';
import Speaker from '../../../icons/speaker';
import SpeakerText from '../../../icons/speakerText';
import NameDialog from './NameDialog/NameDialog';
import VideoInputList from '../../DeviceSelectionDialog/VideoInputList/VideoInputList';
import AudioInputList from '../../DeviceSelectionDialog/AudioInputList/AudioInputList';
import AudioOutputList from '../../DeviceSelectionDialog/AudioOutputList/AudioOutputList';
const testAudio = require('../../../sounds/testAudio.mp3');
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    // height: '85%',
    '& .MuiGrid-container': {
      position: 'relative',
    },
    [theme.breakpoints.down(750)]: {
      height: '100%',
    },
  },
  container: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    padding: '2em 2em 2em 2em',
    [theme.breakpoints.down('xs')]: {
      width: '91vw',
      height: '0%',
      display: 'block',
      margin: '2em 0em 0em 0em',
    },
    [theme.breakpoints.between(600, 750)]: {
      width: '91vw',
      height: '0%',
      display: 'block',
      margin: '2em 1em 0em 2em',
    },
    [theme.breakpoints.down(600)]: {
      width: '100vw',
      height: '0%',
      display: 'block',
      margin: '2em 0em 0em 0em',
    },
  },
  gutterBottom: {
    background: '#323132',
    borderRadius: '10px 10px 0px 0px ',
    color: '#919192',
    fontSize: '16px',
    padding: '7px',
  },
  deviceButton: {
    width: '100%',
    border: '1px solid #aaa',
    margin: '0.4em 0 1.4em 0',
    color: 'white',
    borderRadius: '4px',
    display: 'flex',
    minHeight: '40px',
    justifyContent: 'flex-start',
  },
  speaker: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  activeDeviceButton: {
    background: '#4284f3',
    '&:hover': {
      backgroundColor: '#4284f3',
      color: 'white',
    },
  },
  localPreviewContainer: {
    border: '2px solid rgb(50, 49, 50)',
    background: 'rgb(38, 42, 44)',
    height: '300px',
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '2em',
    flexDirection: 'column',
    alignItems: 'center',
    // paddingTop: '0.5em',
    [theme.breakpoints.down(750)]: {
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  error: {
    marginTop: '0px',
    color: 'red',
  },
  mobileButtonBar: {
    backgroundColor: '#18181a',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
    color: 'white',
  },
  rightSide: {
    color: 'white',
    margin: '2em 0em 0em 3em',
    [theme.breakpoints.up('md')]: {
      margin: '2em 0em 0em 5em',
    },
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  icon: {
    display: 'block',
    margin: '0px 0.2em 0 -0.5em',
  },
  logoMobile: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'flex',
      justifyContent: 'center',
      padding: '30px',
    },
  },
  logoMobileSize: {
    width: '70%',
    height: '100px',
    [theme.breakpoints.down(750)]: {
      width: '70%',
      height: '75px',
      maxHeight: '75px',
    },
    [theme.breakpoints.down(500)]: {
      width: '80%',
      height: '60px',
      maxHeight: '60px',
    },

    [theme.breakpoints.down(400)]: {
      width: '100%',
      height: '55px',
      maxHeight: '55px',
    },
    [theme.breakpoints.down(320)]: {
      width: '130%',
      height: '50px',
      maxHeight: '50px',
    },
    [theme.breakpoints.down(250)]: {
      width: '120%',
      height: '40px',
      maxHeight: '40px',
    },
  },
  mainLogo: {
    position: 'absolute',
    bottom: '4%',
    right: '30px',
    width: '400px',
    [theme.breakpoints.up('lg')]: {
      // bottom: '22%',
    },
    [theme.breakpoints.between(900, 1280)]: {
      bottom: '3%',
    },
    [theme.breakpoints.down(900)]: {
      // bottom: '-5%',
      width: '45%',
    },
  },
  controller: {
    backgroundColor: '#18181a',
    borderRadius: '0px 0px 10px 10px  ',
    padding: '0em 2em',
  },
  contentHeading: {
    margin: '0em 0em 1em 0em',
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 'normal',
  },
  content: {
    margin: '0em 0em 2em 0em',
    fontWeight: 300,
    fontSize: '16px',
  },
  buttonText: {
    color: '#919192',
    fontSize: '16px',
    paddingLeft: '35px',
  },
  mediaIcon: {
    position: 'absolute',
  },
  heading: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '30px',
    },
  },
  btn: {
    background: '#4284f3',
    color: 'white',
    borderRadius: '10px',
    marginTop: '18px',
    '&$buttonDisabled': {
      background: '#323132',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#4284f3',
      color: 'white',
    },
  },
  btn1: {
    background: '#323132',
    color: 'white',
    borderRadius: '10px',
    marginTop: '18px',
    // '&$buttonDisabled': {
    //   background: '#323132',
    //   color: 'white',
    // },
    // '&:hover': {
    //   backgroundColor: '#4284f3',
    //   color: 'white',
    // },
  },
  buttonDisabled: {},
  test: {
    float: 'right',
    color: '#4284f3',
    fontSize: '15px',
    cursor: 'pointer',
    background: 'transparent',
    outline: 'none',
    border: '0px',
    zIndex: 9999,
  },
  inputName: {
    fontSize: '20px',
    background: '#313132',
    border: 'none',
    outline: 'none',
    color: 'white',
    width: '100%',
    caretColor: '#4284f3',
  },
  paddingIcon: {
    paddingLeft: '6px',
  },
  listSection: {
    margin: '0.4em 0 1.4em 0',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
  // setName :(name: string) => void;
  setName?: any;
  handleSubmit?: any;
  handleTerms?: any;
}
export default function DeviceSelectionScreen({
  name,
  roomName,
  setStep,
  setName,
  handleSubmit,
  handleTerms,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const audioOutputDevices = useAudioOutputDevices();
  // const [playbackRate, setPlaybackRate] = useState(1);
  const [video, setVideo] = useState<boolean>(true);
  const [audio, setAudio] = useState<boolean>(true);
  const [activeSpeaker, setActiveSpeaker] = useState<string>();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const disableButtonsFinal =
    isFetching || isAcquiringLocalTracks || isConnecting || (!video && !audio) || !name.trim().length;
  const [showError, setShowError] = useState<boolean>(false);
  const [showNameDialog, setShowNameDialog] = useState<boolean>(false);

  // const [audioPlaying, setAudioPlaying] = useState<boolean>(false);

  // const [play] = useSound(testAudio, {
  //   playbackRate,
  //   volume: 1,
  // });
  // const handleClick = async (e: any) => {
  //   e.preventDefault();
  //   setAudioPlaying(true);
  //   setPlaybackRate(playbackRate);
  //   play();
  //   setTimeout(function() {
  //     setAudioPlaying(false);
  //   }, 1000);
  // };
  const handleJoin = (e: any) => {
    // handleSubmit(e);
    if (video || audio) {
      setShowError(false);
      if (name.trim().length) {
        handleTerms(name, roomName);
      } else if (!name.trim().length) {
        setShowNameDialog(true);
      }
    } else {
      setShowError(true);
    }
    // handleTerms(name, roomName);
  };
  const handleVideo = (vd: boolean) => {
    setVideo(vd);
  };
  const handleAudio = (ad: boolean) => {
    setAudio(ad);
  };
  const handleActiveSpeaker = (text: string) => {
    setActiveSpeaker(text);
  };

  return (
    <div className={classes.wrapper}>
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <div className={classes.container}>
            {/* <Hidden smUp>
          <Typography variant="h5" style={{color:'white'}}>
          ᴇVɪsɪᴛ Cʜᴇᴄᴋ-Iɴ
          </Typography>
        </Hidden> */}
            <Typography variant="h5" style={{ color: 'white' }} className={classes.heading}>
              ᴇVɪsɪᴛ Cʜᴇᴄᴋ-Iɴ
            </Typography>
            {/* <Grid container justify="center"> */}
            {/* <Grid item>
            <Typography variant="h5" className={classes.gutterBottom}>
              Join {roomName}
            </Typography>
          </Grid>  */}

            <div>
              <Typography variant="h5" className={classes.gutterBottom}>
                <input
                  type="text"
                  autoFocus
                  className={classes.inputName}
                  placeholder={name || 'My Name (Required)'}
                  value={name}
                  maxLength={16}
                  onChange={e => setName(e.target.value)}
                />
              </Typography>
              <div className={classes.localPreviewContainer}>
                <LocalVideoPreview checkIn identity={name || ''} />
              </div>
              <div className={classes.mobileButtonBar}>
                {/* <Hidden smUp>
                <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
              </Hidden> */}
                <SettingsMenu
                  disableAudioVideo
                  mobileButtonClass={classes.mobileButton}
                  handleActiveSpeaker={handleActiveSpeaker}
                />
              </div>
              {/* </Grid>
        <Grid item md={5} sm={12} xs={12}> */}
              {/* <Grid
              container
              direction="column"
              justify="space-between"
              style={{  background: 'rgb(24, 24, 26)' }}
            > */}
              <div className={classes.controller}>
                <div>
                  {/* <Hidden xsDown> */}
                  <div>
                    <span className={classes.mediaIcon}>
                      <Speaker />
                    </span>
                    <span className={classes.buttonText}>My Speaker</span>
                    {/* <button className={classes.test} onClick={e => handleClick(e)}>
                      {audioPlaying ? 'Playing...' : 'Test'}
                    </button> */}
                    {/* {audioOutputDevices.map(device => (
                      <li value={device.deviceId} key={device.deviceId} >
                        {device.label}
                      </li>
                    ))} */}
                    {/* {audioOutputDevices[0] && activeSpeaker ? (
                      <div className={`${classes.deviceButton} ${classes.speaker}`}>
                        <SpeakerText />
                        <span className={classes.paddingIcon}>{activeSpeaker ? activeSpeaker : 'Default'}</span>
                      </div>
                    ) : audioOutputDevices[0] ? (
                      <div className={`${classes.deviceButton} ${classes.speaker}`}>
                        <SpeakerText />
                        <span className={classes.paddingIcon}>
                          {audioOutputDevices[0].label ? audioOutputDevices[0].label : 'Default'}
                        </span>
                      </div>
                    ) : (
                      <div className={`${classes.deviceButton} ${classes.speaker}`}>
                        <SpeakerText />
                        <span className={classes.paddingIcon}>Default</span>
                      </div>
                    )} */}
                    <div className={classes.listSection}>
                      {handleActiveSpeaker ? (
                        <AudioOutputList handleActiveSpeaker={handleActiveSpeaker} />
                      ) : (
                        <AudioOutputList />
                      )}
                    </div>
                  </div>
                  <div>
                    <span className={classes.mediaIcon}>
                      <VideoIcon />
                    </span>
                    <span className={classes.buttonText}> My Camera</span>
                    {/* <ToggleVideoButton
                      className={
                        !video ? classes.deviceButton : `${classes.deviceButton} ${classes.activeDeviceButton}`
                      }
                      disabled={disableButtons}
                      handleVideo={handleVideo}
                    /> */}
                    <VideoInputList video={video} handleVideo={handleVideo} disableButtons={disableButtons} />
                  </div>
                  <div>
                    <span className={classes.mediaIcon}>
                      <Microphone />
                    </span>
                    <span className={classes.buttonText}>My Microphone</span>
                    <AudioInputList disableButtons={disableButtons} handleAudio={handleAudio} />
                    {/* <ToggleAudioButton
                      className={
                        !audio ? classes.deviceButton : `${classes.deviceButton} ${classes.activeDeviceButton}`
                      }
                      disabled={disableButtons}
                      handleAudio={handleAudio}
                    /> */}
                  </div>
                  {/* </Hidden> */}
                </div>
                <div className={classes.joinButtons}>
                  <Button
                    variant="contained"
                    data-cy-join-now
                    onClick={handleJoin}
                    // disabled={disableButtonsFinal}
                    classes={disableButtonsFinal ? { root: classes.btn1 } : { root: classes.btn }}
                  >
                    <img src={icon} height="20px" alt="icon" className={classes.icon} />
                    Check In
                  </Button>
                  {showError && (
                    <p className={classes.error}>Enter name and active a camera and/or microphone before checking in</p>
                  )}
                </div>
              </div>
              {/* </Grid> */}
              {/* <Hidden smUp>
              <div className={classes.logoMobile} >
                <img src={logo} height='50' width='350'/>
              </div>
            </Hidden> */}

              <div className={classes.logoMobile}>
                <img src={logo} alt="main mobile logo" className={classes.logoMobileSize} />
              </div>
              {/* </Grid> */}
            </div>
          </div>
        </Grid>
        <Grid item sm={6} md={7} lg={7}>
          <div className={classes.rightSide}>
            <div>
              <div className={classes.contentHeading}>ᴇVɪsɪᴛ Cʜᴇᴄᴋ-Iɴ</div>
              <div className={classes.content}>
                Welcome to eVisits, powered by endlink<sup>®</sup>!{' '}
              </div>
              <div className={classes.content}>eVisits support Chrome, Firefox, IE, and Safari.</div>
              <div className={classes.content}>
                Just fill out your name and select your camera and microphone, then press Check In!
              </div>
              <div>
                <img src={logo} alt="main logo" className={classes.mainLogo} />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <NameDialog
        open={showNameDialog}
        handleTerms={handleTerms}
        roomName={roomName}
        onClose={() => {
          setShowNameDialog(false);
        }}
      />
    </div>
  );
}

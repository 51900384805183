import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { db } from '../../firebase/db.config';
import firebase from 'firebase';
import { firebaseHelper } from '../../firebaseHelper';
import Loader from '../loader';

const useStyles = makeStyles((theme: Theme) => ({
  textColor: {
    color: '#919192',
  },
  participantWaiting: {
    // cursor: 'pointer',
    background: ' #18181a',
    padding: '10px',
    // borderBottom: '1px solid #919192',
    // borderRadius: '10px',
    borderTop: '1px solid #919192',
    [theme.breakpoints.down(750)]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '3px 10px',
      background: 'transparent',
      borderBottom: '1px solid #919192',
      borderRadius: '0px',
      borderTop: '0px',
      alignItems: 'flex-end',
    },
  },
  participantWaitingBtnContainer: {
    padding: '2px 0px',
    textAlign: 'center',
  },
  participantWaitingBtn: {
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#4284f3',
    border: 'none',
    borderRadius: '5px',
    padding: '3px 8px',
    minHeight: '25px',
    minWidth: '60px',
  },
  disabledParticipantWaitingBtn: {
    backgroundColor: '#323132',
  },
}));

export default function ParticipantRequesting({
  visitor,
  roomName,
  currentParticipantsLength,
  roomType,
  activeParticipant,
  activeParticipantLength,
}: {
  visitor?: string;
  roomName?: any;
  currentParticipantsLength?: any;
  roomType?: any;
  activeParticipant?: any;
  activeParticipantLength?: any;
}) {
  const classes = useStyles();
  const [docData, setDocData] = useState([]);
  // const [docDataTemp, setDocDataTemp] = useState([]);
  const [loader, setLoader] = useState([]);
  const [idDoc, setidDoc] = useState<string>();

  useEffect(() => {
    const fetchCollection = async () => {
      var idDocAdded = await firebaseHelper(roomName);
      setidDoc(idDocAdded);
      db.collection('roomDetails')
        .doc(idDocAdded)
        .onSnapshot(snap => {
          if (snap.exists) {
            const snapResult = snap.data()?.waitingParticipantsArray;
            // console.log("snapResult", snapResult);
            if (snapResult) {
              setDocData(snapResult);
            }
          }
        });
    };
    fetchCollection();
  }, []);

  // useEffect(() => {
  //   if (docData && docDataTemp && docData.length <= docDataTemp.length) {
  //     setDocData(docDataTemp);
  //   }
  // }, [docDataTemp]);

  useEffect(() => {
    setDocData(oldState =>
      oldState.filter((item: any) => {
        if (!activeParticipant.includes(item.participantName)) {
          setLoader(oldState => oldState.filter(state => state !== item.participantName));
        }
        return !activeParticipant.includes(item.participantName);
      })
    );
  }, [activeParticipant && activeParticipant.length]);

  const handleBtnClick = async (item?: any) => {
    setLoader(oldState => [...oldState, item.participantName as never]);
    const admitObj = {
      eVisitName: roomName,
      inviteeName: item.participantName,
      admittedBy: visitor,
      timeStamp: firebase.firestore.Timestamp.now(),
    };
    await db
      .collection('roomDetails')
      .doc(idDoc)
      .update({
        admitDetails: firebase.firestore.FieldValue.arrayUnion(admitObj),
        waitingParticipantsArray: firebase.firestore.FieldValue.arrayRemove(item),
      });
    // await db
    // .collection('roomDetails')
    // .doc(idDoc)
    // .update({
    //   waitingParticipantsArray: firebase.firestore.FieldValue.arrayUnion({participantName:item.participantName,allow:true}),
    // });
  };

  return (
    <div className={classes.textColor}>
      {docData?.map((item: any) => (
        // item.allow===false &&
        <div className={classes.participantWaiting} key={item.participantName}>
          {item.participantName} (Waiting to Join)
          <div className={classes.participantWaitingBtnContainer}>
            {roomType === 'small' ? (
              <button
                disabled={currentParticipantsLength === 2}
                className={
                  currentParticipantsLength === 2
                    ? `${classes.participantWaitingBtn} ${classes.disabledParticipantWaitingBtn}`
                    : classes.participantWaitingBtn
                }
                onClick={() => handleBtnClick(item)}
              >
                {loader.includes(item.participantName as never) ? (
                  <Loader />
                ) : currentParticipantsLength === 2 ? (
                  'Room at Max; Cannot Admit'
                ) : (
                  'Admit'
                )}
              </button>
            ) : (
              <button
                disabled={currentParticipantsLength === 50}
                className={
                  currentParticipantsLength === 50
                    ? `${classes.participantWaitingBtn} ${classes.disabledParticipantWaitingBtn}`
                    : classes.participantWaitingBtn
                }
                onClick={() => handleBtnClick(item)}
              >
                {loader.includes(item.participantName as never) ? (
                  <Loader />
                ) : currentParticipantsLength === 50 ? (
                  'Room at Max; Cannot Admit'
                ) : (
                  'Admit'
                )}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-phone-input-2/lib/style.css';
import { axiosConfig, frontURL } from '../../axiosBaseURL';
import { db, functions } from '../../firebase/db.config';
import firebase from 'firebase';
import ReactFlagsSelect from 'react-flags-select';
import { countries, countryCodes } from './countryData';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firebaseHelper } from '../../firebaseHelper';
import { isValid } from './isValid';
import Loader from '../loader';

interface InviteDialogProps {
  open: boolean;
  onClose(): void;
  roomName?: any;
  visitor?: any;
  roomType?: any;
  numberOfInvitationSend?: any;
}
toast.configure();

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '500px',
    },
  },
  container1: {
    background: '#18181a',
    border: '2px solid #323132',
    color: '#4284f3',
  },
  container2: {
    borderLeft: '2px solid #323132',
    borderRight: '2px solid #323132',
    background: '#18181a',
  },
  container3: {
    background: '#18181a',
    border: '2px solid #323132',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButton-root': {
      padding: '0px 10px',
      margin: '0px 20px',
      height: '25px',
    },
  },
  text: {
    color: '#aaa',
    textAlign: 'center',
  },
  inviteBtn: {
    color: 'white',
    background: '#4284f3',
    cursor: 'pointer',
  },
  cancelBtn: {
    color: 'white',
    background: '#323132',
    cursor: 'pointer',
  },
  input: {
    padding: '10px 5px 10px 10px',
    outline: 'none',
    border: '1px solid #aaa',
    borderRadius: '5px',
    background: '#18181a',
    color: 'white',
    // width:'47% !important',
    width: '100% !important',
    textOverflow: 'ellipsis',
    fontSize: '15px',
  },
  input1: {
    padding: '10px 5px 10px 95px',
  },
  inputBox: {
    flex: '0.7',
    margin: '0px 5px',
    [theme.breakpoints.down(750)]: {
      margin: '10px 5px',
    },
  },
  inputBox1: {
    flex: '1',
    margin: '0px 5px',
    display: 'flex',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      position: 'relative',
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px',
    alignItems: 'center',
    [theme.breakpoints.down(750)]: {
      flexDirection: 'column',
      alignItems: 'inherit',
    },
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '-20px',
    padding: '0px 10px',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  errorText: {
    color: 'red',
    width: '50% !important',
  },
  addBtn: {
    float: 'right',
    cursor: 'pointer',
    color: 'white',
  },
  removeBtn: {
    color: 'crimson',
    cursor: 'pointer',
    [theme.breakpoints.down(750)]: {
      margin: '7px 0px',
      textAlign: 'center',
    },
  },
  countryCodeSelector: {
    position: 'absolute',
    '& .ReactFlagsSelect-module_selectBtn__19wW7': {
      color: '#919192',
      width: '80px',
      maxWidth: '80px',
      padding: '5px',
      borderRight: '1px solid #aaa',
      borderLeft: '1px solid #aaa',
      borderTop: '1px solid #aaa',
      borderBottom: '0px',
      borderRadius: '5px 0px 0px 5px',
    },
    '& .ReactFlagsSelect-module_selectValue__152eS': {
      padding: '0px',
    },
    '& .ReactFlagsSelect-module_selectOptions__3LNBJ': {
      background: '#232325',
      position: 'fixed',
    },
    '& .ReactFlagsSelect-module_label__27pw9': {
      color: '#919192',
    },
    '& .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM:hover': {
      backgroundColor: 'grey',
      '& .ReactFlagsSelect-module_label__27pw9': {
        color: 'white',
      },
    },
  },
  errorDuplicateText: {
    color: 'red',
    marginBottom: '0px',
  },
  errorText1: {
    color: 'red',
    display: 'none',
    width: '50% !important',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      textAlign: 'initial',
      marginTop: '5px',
      marginBottom: '0px',
      width: '100% !important',
    },
  },
  loader: {
    paddingRight: '8px',
  },
  maxWarning: {
    color: 'red',
    textAlign: 'center',
    background: '#18181a',
    padding: '0 136px',
    borderLeft: '2px solid #323132',
    borderRight: '2px solid #323132',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
}));

function InviteDialog({ open, onClose, roomName, visitor, roomType, numberOfInvitationSend }: InviteDialogProps) {
  const classes = useStyles();
  const [inputList, setInputList] = useState([{ name: '', contactInfo: '' }]);
  const [errorIndex, setErrorIndex] = useState<number>();
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const [selected, setSelected] = useState(['US']);
  const letterNumber = /^(?=.*[0-9])[- +()0-9]+$/;
  const [emailError, setEmailError] = useState<number>();
  const [contactError, setContactError] = useState<number>();
  const [loader, setLoader] = useState(false);

  const emailValidatorRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const url = window.location.origin + `/room/${roomName}/${roomType}`;
  const addDataToFirebase = async (name?: any, contactInfo?: any) => {
    if (roomName) {
      var idDocAdded = await firebaseHelper(roomName);
      var hnm;
      await db
        .collection('roomDetails')
        .doc(idDocAdded)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            hnm = doc.data()?.hostName;
          }
        });
      const invitationObj = {
        inviteeContactInfo: contactInfo,
        inviteeName: name,
        invitedBy: hnm,
        timeStamp: firebase.firestore.Timestamp.now(),
      };
      await db
        .collection('roomDetails')
        .doc(idDocAdded)
        .update({
          invites: firebase.firestore.FieldValue.arrayUnion(invitationObj),
          // timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
  };
  const handleInputChange = (a: any, b: any, e: any, index: any) => {
    const list: any = [...inputList];
    if (e.target) {
      const { name, value } = e.target;
      list[index][name] = value;
    } else {
      list[index]['contactInfo'] = e;
    }
    setInputList(list);
  };
  useEffect(() => {
    inputList.forEach((item, index) => {
      if (index > 0) {
        if (
          inputList[index].contactInfo.trim().length !== 0 &&
          inputList[index - 1].contactInfo.trim().length !== 0 &&
          inputList[index].contactInfo === inputList[index - 1].contactInfo
        )
          setDuplicateError(true);
        else setDuplicateError(false);
      }
      if (!item.contactInfo.match(letterNumber) && item.contactInfo.trim().length !== 0) {
        if (!emailValidatorRegex.test(item.contactInfo)) {
          setEmailError(index);
          setErrorIndex(index);
        } else {
          setEmailError(100);
        }
      } else {
        setEmailError(100);
      }
    });
  }, [inputList]);
  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    const list1 = [...selected];
    list1.splice(index, 1);
    setSelected(list1);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { name: '', contactInfo: '' }]);
    setSelected([...selected, 'US']);
  };
  const handleCountryChange = (e: any, index: any) => {
    const list: any = [...selected];
    list[index] = e;
    setSelected(list);
  };
  const handleInviteBtn = () => {
    if (!loader) {
      setLoader(true);
      let breakloop = false;
      inputList.forEach((item, index) => {
        if (!item.contactInfo.match(letterNumber) && item.contactInfo.trim().length !== 0) {
          if (!emailValidatorRegex.test(item.contactInfo)) {
            setEmailError(index);
            setErrorIndex(index);

            breakloop = true;
          } else {
            setEmailError(100);
            breakloop = false;
          }
        } else {
          setEmailError(100);
          breakloop = false;
        }
      });
      selected.forEach((item, index) => {
        if (!selected[index] && inputList[index].contactInfo.match(letterNumber)) {
          setErrorIndex(index);
          breakloop = true;
        }
        if (selected[index] && inputList[index].contactInfo.match(letterNumber)) {
          if (!isValid(selected[index], inputList[index].contactInfo)) {
            setErrorIndex(index);
            setContactError(index);
            breakloop = true;
          } else {
            setErrorIndex(100);
            setContactError(100);
            breakloop = false;
          }
        }
      });
      inputList.forEach((item, index) => {
        if (item.name.trim().length === 0 || item.contactInfo.trim().length === 0) {
          setErrorIndex(index);
          breakloop = true;
        }
      });
      if (breakloop === false && duplicateError === false) {
        inputList.forEach((item, index) => {
          if (item.contactInfo.match(letterNumber)) {
            var abc = '';
            Object.keys(countryCodes).forEach((item: any, i) => {
              if (item === selected[index]) {
                abc = Object.values(countryCodes)[i];
              }
            });
            var x = abc.split('+')[1] + item.contactInfo.replace(/\D+/g, '');
            var item = {
              name: item.name,
              contactInfo: x,
            };
            // axiosConfig.post('/telehealthsendsms', { item, url }).catch(function(error) {
            //   console.log('error ----', error);
            // });
            const telehealthsendsms = functions.httpsCallable('telehealthsendsms');
            telehealthsendsms({ item, url })
              .then(res => {
                if (breakloop === false && duplicateError === false && res.data.status === 'SMS sent') {
                  setLoader(false);
                  toast.dark('Invitation(s) sent!', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setInputList([{ name: '', contactInfo: '' }]);
                  setSelected(['US']);
                  onClose();
                }
              })
              .catch(err => err);
            addDataToFirebase(item.name, x);
          } else {
            // axiosConfig.post('/telehealthsendemail', { item, url }).catch(function(error) {
            //   console.log('error ----', error);
            // });
            const telehealthsendemail = functions.httpsCallable('telehealthsendemail');
            telehealthsendemail({ item, url })
              .then(res => {
                if (breakloop === false && duplicateError === false && res.data.status === 'Email sent') {
                  setLoader(false);
                  toast.dark('Invitation(s) sent!', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setInputList([{ name: '', contactInfo: '' }]);
                  setSelected(['US']);
                  onClose();
                }
              })
              .catch(err => err);
            addDataToFirebase(item.name, item.contactInfo);
          }
        });
      } else {
        setLoader(false);
      }
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} className={classes.wrapper}>
      <DialogTitle className={classes.container1}>Invite Someone to Join</DialogTitle>
      <DialogContent className={classes.container2}>
        <DialogContentText className={classes.text}>
          By inviting people into this session, I am authorizing them to participate in this conversation about the
          patient’s Protected Health Information
        </DialogContentText>
        {duplicateError && <p className={classes.errorDuplicateText}>You have already invited this user</p>}

        {inputList.map((x, i) => {
          return (
            <div key={i}>
              <div className={classes.inputContainer}>
                <div className={classes.inputBox}>
                  <input
                    name="name"
                    placeholder="Name"
                    value={x.name}
                    className={classes.input}
                    maxLength={16}
                    onChange={e => handleInputChange('', '', e, i)}
                  />
                  {errorIndex === i && (
                    <p className={classes.errorText1}>{inputList[i].name.trim().length === 0 && 'Required'}</p>
                  )}
                </div>
                <div className={classes.inputBox1}>
                  <ReactFlagsSelect
                    className={classes.countryCodeSelector}
                    countries={countries}
                    customLabels={countryCodes}
                    selected={selected[i]}
                    fullWidth={false}
                    placeholder="Code"
                    onSelect={code => handleCountryChange(code, i)}
                  />
                  <input
                    className={`${classes.input} ${classes.input1}`}
                    name="contactInfo"
                    placeholder="SMS # or Email"
                    value={x.contactInfo}
                    onChange={e => handleInputChange('', '', e, i)}
                  />
                  {errorIndex === i && (
                    <p className={classes.errorText1}>
                      {inputList[i].contactInfo.trim().length === 0
                        ? 'Valid SMS # or Email Required'
                        : emailError === i
                        ? 'Invalid email address'
                        : contactError === i
                        ? 'Invalid SMS address'
                        : ''}
                    </p>
                  )}
                </div>
                {inputList.length !== 1 && (
                  <span className={classes.removeBtn} onClick={() => handleRemoveClick(i)}>
                    <DeleteIcon />
                  </span>
                )}
              </div>
              {errorIndex === i && (
                <div className={classes.errorContainer}>
                  <p className={classes.errorText}>{inputList[i].name.trim().length === 0 && 'Required'}</p>
                  <p className={classes.errorText}>
                    {inputList[i].contactInfo.trim().length === 0
                      ? 'Valid SMS # or Email Required'
                      : emailError === i
                      ? 'Invalid email address'
                      : contactError === i
                      ? 'Invalid SMS address'
                      : ''}
                  </p>
                </div>
              )}
            </div>
          );
        })}
        {roomType !== 'small' && inputList.length < 2 && (
          <span className={classes.addBtn} onClick={handleAddClick}>
            <AddIcon />
          </span>
        )}
      </DialogContent>
      {numberOfInvitationSend > 0 && roomType === 'small' && (
        <div className={classes.maxWarning}>
          <p>Please be aware that TeleHealth sessions support a maximum of 2 users per session.</p>
        </div>
      )}
      <DialogActions className={classes.container3}>
        <Button className={classes.cancelBtn} onClick={onClose} variant="contained" autoFocus>
          Cancel
        </Button>
        <Button className={classes.inviteBtn} onClick={handleInviteBtn} variant="contained" autoFocus>
          {loader && (
            <div className={classes.loader}>
              <Loader />
            </div>
          )}
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InviteDialog;

import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import { makeStyles } from '@material-ui/core';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import PHIConsent from './components/PHIConsent/PHIConsent';
import { useAppState } from './state';
import useVideoContext from './hooks/useVideoContext/useVideoContext';
import { db } from './firebase/db.config';
import firebase from 'firebase';
import WaitingRoom from './components/WaitingRoom/WaitingRoom';
import { getCredentials } from './firebaseHelper';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './styles/bootstrap.min.css';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#232325',
    // height:'100vh'
  },
}));

export default function App() {
  const roomState = useRoomState();
  const classes = useStyles();
  const [visitor, setVisitor] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>();
  const [showWaitingRoom, setShowWaitingRoom] = useState<boolean>(false);
  const [nm, setnm] = useState<string>('');
  const [rnm, setrnm] = useState<string>('');
  const [pusherKey, setPusherKey] = useState<string>('');
  const [pusherCluster, setPusherCluster] = useState<string>('');
  const [roomType, setRoomType] = useState<string>('small');
  const { getToken, isFetching } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  useEffect(() => {
    getCredentials().then((res: any) => {
      setPusherKey(res.pusherKey);
      setPusherCluster(res.pusherCluster);
    });
  }, []);
  const handleRoomType = (type: string) => {
    setRoomType(type);
  };
  const handleTerms = (name?: any, room?: any) => {
    if (name || room) {
      name && setnm(name);
      name && setVisitor(name);
      room && setrnm(room);
      setTermsAccepted(!termsAccepted);
      setShowWaitingRoom(false);
    } else {
      setTermsAccepted(false);
    }
  };

  const callGetToken = () => {
    getToken(nm, rnm).then(token => {
      connect(token)
        .then(() => {
          setShowWaitingRoom(false);
          setTermsAccepted(false);
        })
        .catch(error => console.log('failure', error));
    });
  };
  const fetchToken = async () => {
    try {
      const res = await db
        .collection('roomDetails')
        .where('roomName', '==', rnm)
        .get();
      var clientDate = new Date();
      var currentDate = clientDate.getDate();
      var currentMonth = clientDate.getMonth();
      var idDocAdded;
      var createNewDocument;
      var id, exists, docDate, docMonth;
      res.docs.forEach(item => {
        id = item.id;
        exists = item.exists;
        docDate = item
          .data()
          .timeStamp.toDate()
          .getDate();
        docMonth = item
          .data()
          .timeStamp.toDate()
          .getMonth();
        if (exists && currentDate === docDate && currentMonth === docMonth) {
          idDocAdded = id;
          createNewDocument = false;
        }
      });

      if (createNewDocument === false) {
      } else {
        const aa = await db.collection('roomDetails').add({
          roomName: rnm,
          timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
        idDocAdded = aa.id;
      }
      if (nm === 'doctor') {
        getToken(nm, rnm).then(token => {
          connect(token);
        });
        await db
          .collection('roomDetails')
          .doc(idDocAdded)
          .update({
            hostName: nm,
            roomType,
          });
        setShowWaitingRoom(false);
      } else {
        const obj = {
          participantName: nm,
          timeStamp: firebase.firestore.Timestamp.now(),
        };
        await db
          .collection('roomDetails')
          .doc(idDocAdded)
          .update({
            waitingParticipantsArray: firebase.firestore.FieldValue.arrayUnion(obj),
            // waitingParticipantsArray: firebase.firestore.FieldValue.arrayUnion(nm),
          });
        setShowWaitingRoom(true);
      }
    } catch (error) {
      console.log('ERROR fetchToken ', error);
    }
  };

  const height = useHeight();
  return (
    <Router>
      <Switch>
        <Route path="/:room?/:roomName?">
          <Container style={{ height }}>
            {roomState === 'disconnected' ? (
              !termsAccepted ? (
                <PreJoinScreens
                  setVisitor={setVisitor}
                  handleTerms={handleTerms}
                  handleRoomType={handleRoomType}
                  roomType={roomType}
                />
              ) : !showWaitingRoom ? (
                <PHIConsent fetchToken={fetchToken} disableBtn={disableButtons} />
              ) : (
                <WaitingRoom visitor={visitor} handleTerms={handleTerms} roomname={rnm} callGetToken={callGetToken} />
              )
            ) : (
              <div className={classes.wrapper}>
                <ReconnectingNotification />

                <Room visitor={visitor} handleTerms={handleTerms} pusherKey={pusherKey} pusherCluster={pusherCluster} />
              </div>
            )}
          </Container>
        </Route>
      </Switch>
    </Router>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import LeftColumn from '../LeftColumn/LeftColumn';
import { makeStyles, Theme } from '@material-ui/core';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import TetrisIcon from '../../icons/TetrisIcon';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSound from 'use-sound';
import ToggleLeftButton from '../Buttons/ToggleLeftButton/ToggleLeftButton';
import ToggleRightButton from '../Buttons/ToggleRightButton/ToggleRightButton';
import ProfileBar from '../ProfileBar/ProfileBar';
import TetrisStart from '../TetrisStart/TetrisStart';
import ChatHeading from '../Chat/ChatHeading';
import ChatInput from '../Chat/ChatInput';
import ChatItem from '../Chat/ChatItem';
import Timer from '../Timer/Timer';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import YoutubePlayer from '../YoutubePlayer/YoutubePlayer';
import { db } from '../../firebase/db.config';
import { firebaseHelper } from '../../firebaseHelper';
import YoutubeDropdown from '../YoutubeDropdown/YoutubeDropdown';
import { updateParticipants } from '../Room/helper';
import useRoomDetails from '../../hooks/useRoomDetails/useRoomDetails';
const waitingAudio = require('../../sounds/waiting.mp3');
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    background: '#232325',
    [theme.breakpoints.down(750)]: {
      flexDirection: 'column',
      background: '#232325',
    },
  },
  left: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    background: '#232325',
    padding: '3px',
    position: 'relative',
    fontFamily: 'arial',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(750)]: {
      position: 'absolute',
      width: '160px',
      height: '169px',
      minWidth: '160px',
      right: '2%',
      // top: '10%',
      top: '9%',
      zIndex: '9999',
      background: 'transparent',
    },
  },
  middle: {
    background: '#232325',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down(750)]: {
      height: '100%',
      maxHeight: '75vh',
    },
  },
  middle1: {
    [theme.breakpoints.down(750)]: {
      // maxHeight: '70vh',
      maxHeight: '80vh',
      height: '100%',
    },
  },
  right: {
    maxWidth: '240px',
    minWidth: '240px',
    background: '#232325',
    minHeight: '50vh',
    maxHeight: '100vh',
    position: 'relative',
    fontFamily: 'arial',
    [theme.breakpoints.down(750)]: {
      zIndex: '9999',
      maxWidth: '100%',
    },
  },
  messageContainer: {
    background: '#2a2829',
    height: '82%',
    width: '98%',
    position: 'relative',
    left: '1%',
    overflow: 'scroll',
    [theme.breakpoints.down(750)]: {
      height: '38vh',
      position: 'relative',
      left: '1%',
      width: '98%',
    },
  },
  hiddenDisconnect: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      width: '99%',
      // zIndex: '9999',
      marginTop: '8px',
    },
  },
  gameActive: {
    opacity: '1',
  },
  gameTriggerMobile: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      fill: '#4284f3',
      stroke: '#232325',
      textAlign: 'center',
      position: 'absolute',
      bottom: '3%',
      left: '2%',
      opacity: '1',
      cursor: 'pointer',
      '& svg': {
        width: '60px',
      },
    },
  },
  gameTriggerMobile1: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      fill: '#4284f3',
      stroke: '#232325',
      position: 'relative',
      width: '60px',
      left: '1%',
      opacity: '1',
      cursor: 'pointer',
      '& svg': {
        width: '60px',
        height: '50px',
      },
    },
  },
  counterMobile: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#919192',
      textAlign: 'center',
      fontSize: '20px',
      padding: '2px',
    },
  },
  space: {
    padding: '4px',
  },
  partStatus: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    flexDirection: 'column',
    // position: 'absolute',
    textAlign: 'center',
    minHeight: ' 36vh',
    // [theme.breakpoints.down(750)]: {
    //  top:'43px',
    //  height: '87vh',
    // }
    [theme.breakpoints.down(750)]: {
      // top:'43px',
      // height: '87vh',
    },
  },
  textContainer1: {
    justifyContent: 'flex-end',
  },
  upperText: {
    fontSize: '28px',
    padding: '0px',
    margin: '0px',
    [theme.breakpoints.down(750)]: {
      fontSize: '21px',
      fontWeight: 'bolder',
    },
    [theme.breakpoints.down(250)]: {
      fontSize: '14px',
      fontWeight: 'bolder',
    },
  },
  restText1: {
    fontSize: '14px',
    padding: '0px',
    margin: '0px',
  },
  restText2: {
    fontSize: '14px',
    padding: '0px',
    margin: '0px',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  belowText: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      margin: '0',
      padding: '0',
    },
  },
  gameTrigger: {
    fill: '#4284f3',
    stroke: '#232325',
    textAlign: 'center',
    opacity: '0.25',
    cursor: 'pointer',
    paddingTop: '5px',
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
    },
  },
  youtubeTrigger: {
    textAlign: 'center',
    '& .MuiSvgIcon-root': {
      fill: '#4284f3',
      fontSize: '6.5rem',
      opacity: '0.25',
      cursor: 'pointer',
    },
    display: 'none',

    [theme.breakpoints.down(750)]: {
      display: 'block',
    },
  },
  youtubeActive: {
    '& .MuiSvgIcon-root': {
      opacity: '1',
    },
  },
  youtubeTriggerMobile: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      fill: '#4284f3',
      stroke: '#232325',
      textAlign: 'center',
      position: 'absolute',
      bottom: '3%',
      left: '2%',
      cursor: 'pointer',
      '& svg': {
        width: '60px',
      },
      '& .MuiSvgIcon-root': {
        fill: '#4284f3',
        fontSize: '6.5rem',
        opacity: '1',
        cursor: 'pointer',
      },
    },
  },
  youtubeTriggerMobile1: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      fill: '#4284f3',
      stroke: '#232325',
      position: 'relative',
      width: '60px',
      left: '1%',
      opacity: '1',
      cursor: 'pointer',
      '& svg': {
        width: '60px',
        height: '50px',
      },
      '& .MuiSvgIcon-root': {
        fill: '#4284f3',
        fontSize: '6.5rem',
        opacity: '1',
        cursor: 'pointer',
      },
    },
  },
  admitWarning: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      color: '#919192',
      padding: '0px 7px',
      display: 'block',
    },
  },
}));

interface ChatType {
  username: string;
  message: any;
  time: number;
}
const initialChats: Array<ChatType> = [];
export const NoParticpantText = ({
  handleTetrisIcon,
  handlePlayYoutube,
}: {
  handleTetrisIcon?: any;
  handlePlayYoutube?: any;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.textContainer}>
      <p className={classes.upperText}>Welcome to the meeting!</p>
      <p className={classes.upperText}>Sit tight. Someone should admit you soon.</p>
      <p className={classes.restText1}>Want to have some fun while you wait?</p>
      <p className={classes.restText2}>Click the YouTube icon or T to your left!</p>
      <p className={classes.belowText}>Click the YouTube icon or T below!</p>
      <div className={classes.youtubeTrigger} onClick={handlePlayYoutube}>
        <YouTubeIcon />
      </div>
      <div className={classes.gameTrigger} onClick={handleTetrisIcon}>
        <TetrisIcon />
      </div>
    </div>
  );
};

export default function WaitingRoom({
  visitor,
  handleTerms,
  roomname,
  callGetToken,
}: {
  visitor?: string;
  handleTerms?: any;
  roomname?: string;
  callGetToken?: any;
}) {
  const [leftbtn, setLeftbtn] = useState<boolean>(true);
  const [rightbtn, setRightbtn] = useState<boolean>(false);
  const [tetris, setTetris] = useState<boolean>(false);
  const [playYoutube, setPlayYoutube] = useState<boolean>(false);
  const [activeParticipantLength, setActiveParticipantLength] = useState<number>(0);
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const { roomId, waitingParticipantsArray } = useRoomDetails();
  const isReconnecting = roomState === 'reconnecting';
  const [playbackRate, setPlaybackRate] = useState(1);
  const { room } = useVideoContext();
  const classes = useStyles();
  const [chats, setChats] = useState(initialChats);
  const [totalChatsLength, setTotalChatsLength] = useState<number>(0);
  const [chatsLength, setChatsLength] = useState<number>(0);
  const chatsFinal: Array<ChatType> = [];
  const [docData, setDocData] = useState([] as any);
  var audio = new Audio(waitingAudio);
  const time = Timer();
  const [text, setText] = useState<string>('');
  const [typing, setTyping] = useState<string>('');
  const [playListId, setPlayListId] = useState<string>();
  const [play] = useSound(waitingAudio, {
    playbackRate,
    volume: 1,
  });
  const handleChats = async (data: ChatType) => {
    await setPlaybackRate(playbackRate);
    await play();
    chatsFinal.push(data);
    setTotalChatsLength(chatsFinal.length);
    setChats(chatsFinal);
  };
  const handleLeftBtn = () => {
    setLeftbtn(!leftbtn);
  };
  const handleRightBtn = () => {
    setChatsLength(totalChatsLength);
    setRightbtn(!rightbtn);
  };
  const handleTetrisIcon = () => {
    if (activeParticipantLength === 0) {
      setPlayYoutube(false);
      setTetris(!tetris);
    } else setTetris(false);
  };
  const handlePlayYoutube = () => {
    if (activeParticipantLength === 0) {
      setTetris(false);
      setPlayYoutube(!playYoutube);
    } else setPlayYoutube(false);
  };
  const handleYoutubePlaylists = (playlist: any) => {
    setPlayListId(playlist);
  };
  const handleActiveParticipant = (len: number) => {
    setActiveParticipantLength(len);
  };
  const handleSubmit = async () => {};
  const handleEnter = (e: any) => {};
  const handleTextChange = (e: any) => {
    setText(e.target.value);
  };
  const handleDimensions = () => {
    if (window.innerWidth > 749) {
      setLeftbtn(true);
    }
    if (window.innerWidth < 749) {
      setLeftbtn(false);
    }
  };

  useEffect(() => {
    let unmounted = false;
    audio.play();
    // if(!unmounted){
    var idDocAdded: any;
    const fetchCollection = async () => {
      idDocAdded = await firebaseHelper(roomname);
      db.collection('roomDetails')
        .doc(idDocAdded)
        .onSnapshot(snap => {
          // console.log("snap.data()", snap.data());
          const aa = snap.data()?.waitingParticipantsArray;
          // const allowed = snap.data()?.allowedParticipantsArray;
          setDocData(aa);
          // if (aa && !aa.includes(visitor)) {
          //   // if (!allowed.includes(visitor)) {
          //   if (!unmounted) {
          //     callGetToken();
          //     unmounted = true;
          //     // }
          //   }
          // }
          var call = true;
          aa.forEach((element: any) => {
            if (element.participantName === visitor) {
              call = false;
            }
          });
          if (call) {
            if (!unmounted) {
              callGetToken();
              unmounted = true;
            }
          }
        });
    };
    fetchCollection();
    handleDimensions();
    window.addEventListener('resize', handleDimensions);
    return () => {
      window.removeEventListener('resize', handleDimensions);
    };
  }, []);

  const removeParticaipants = useCallback(
    (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const filteresParticipants = waitingParticipantsArray.filter(par => par.participantName !== visitor);
      updateParticipants({ participants: filteresParticipants, roomId: roomId });
    },
    [roomId, waitingParticipantsArray]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', removeParticaipants);
    return () => {
      window.removeEventListener('beforeunload', removeParticaipants);
    };
  }, [removeParticaipants]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.hiddenDisconnect}>
          <EndCallButton handleTerms={handleTerms} showOnlyWaitingRoom={true} />
        </div>
        {leftbtn && (
          <div className={classes.left}>
            <LeftColumn
              visitor={visitor}
              handleLeftBtn={handleLeftBtn}
              isReconnecting={isReconnecting}
              isSharingScreen={isSharingScreen}
              tetris={tetris}
              toggleScreenShare={toggleScreenShare}
              roomName={room.name}
              activeParticipantLength={activeParticipantLength}
              handleTetrisIcon={handleTetrisIcon}
              time={time}
              handleTerms={handleTerms}
              handlePlayYoutube={handlePlayYoutube}
              playYoutube={playYoutube}
              showOnlyWaitingRoom={true}
              handleYoutubePlaylists={handleYoutubePlaylists}
            />
          </div>
        )}
        {playYoutube && (
          <div className={classes.partStatus}>
            <YoutubeDropdown handleYoutubePlaylists={handleYoutubePlaylists} />
          </div>
        )}
        {!leftbtn ? (
          <div className={classes.middle}>
            {activeParticipantLength === 0 ? (
              <>
                {tetris && <TetrisStart roomName={roomname} visitor={visitor} />}
                {playYoutube && <YoutubePlayer playListId={playListId} roomName={roomname} visitor={visitor} />}
                {!tetris && !playYoutube && (
                  <NoParticpantText handleTetrisIcon={handleTetrisIcon} handlePlayYoutube={handlePlayYoutube} />
                )}
              </>
            ) : (
              <>
                <NoParticpantText handleTetrisIcon={handleTetrisIcon} handlePlayYoutube={handlePlayYoutube} />
              </>
            )}
          </div>
        ) : (
          <div className={`${classes.middle} ${classes.middle1}`}>
            {activeParticipantLength === 0 ? (
              <>
                {tetris && <TetrisStart roomName={roomname} visitor={visitor} />}
                {playYoutube && <YoutubePlayer playListId={playListId} roomName={roomname} visitor={visitor} />}
                {!tetris && !playYoutube && (
                  <NoParticpantText handleTetrisIcon={handleTetrisIcon} handlePlayYoutube={handlePlayYoutube} />
                )}
              </>
            ) : (
              <>
                <NoParticpantText handleTetrisIcon={handleTetrisIcon} handlePlayYoutube={handlePlayYoutube} />
              </>
            )}
          </div>
        )}
        {!leftbtn && !tetris && !playYoutube ? (
          <>
            <ProfileBar
              visitor={visitor}
              isReconnecting={isReconnecting}
              handleLeftBtn={handleLeftBtn}
              showWaitingRoom={true}
            />
          </>
        ) : (
          <div className={classes.partStatus}>
            {/* <ParticipantStatus roomName={room.name}/> */}
            {/* <div className={classes.counterMobile}>{time}</div> */}
          </div>
        )}
        {/* {!leftbtn && !tetris ? <div className={classes.counterMobile}>{time}</div> : <div></div>} */}
        {activeParticipantLength === 0 && !tetris && !playYoutube ? (
          <div className={classes.counterMobile}>
            <AccessTimeIcon />
            <div className={classes.space} />
            {time}
          </div>
        ) : (
          // <div className={classes.counterMobile}>{time}</div>
          <></>
        )}
        {tetris ? (
          rightbtn ? (
            <div className={`${classes.gameTriggerMobile1} ${classes.gameActive}`} onClick={handleTetrisIcon}>
              <TetrisIcon />
            </div>
          ) : (
            <div className={`${classes.gameTriggerMobile} ${classes.gameActive}`} onClick={handleTetrisIcon}>
              <TetrisIcon />
            </div>
          )
        ) : (
          <div></div>
        )}
        {playYoutube ? (
          rightbtn ? (
            <>
              <div className={`${classes.youtubeTriggerMobile1} ${classes.youtubeActive}`} onClick={handlePlayYoutube}>
                <YouTubeIcon />
              </div>
              {/* <div className={classes.partStatus}>
            <YoutubeDropdown handleYoutubePlaylists={handleYoutubePlaylists}/>
            </div>            */}
            </>
          ) : (
            <>
              <div className={`${classes.youtubeTriggerMobile} ${classes.youtubeActive}`} onClick={handlePlayYoutube}>
                <YouTubeIcon />
              </div>
              {/* <div className={classes.partStatus}>
            <YoutubeDropdown handleYoutubePlaylists={handleYoutubePlaylists}/>
            </div> */}
            </>
          )
        ) : (
          <div></div>
        )}
        {rightbtn && (
          <div className={classes.right}>
            <ChatHeading handleRightBtn={handleRightBtn} />
            <div className={classes.messageContainer}>
              {chats.map((item: ChatType, index: number) => {
                return <ChatItem key={index} item={item} />;
              })}
            </div>
            <ChatInput
              text={text}
              typing={typing}
              handleEnter={handleEnter}
              handleTextChange={handleTextChange}
              handleSubmit={handleSubmit}
            />
          </div>
        )}
        {!leftbtn && <ToggleLeftButton visitor={visitor} handleLeftBtn={handleLeftBtn} />}
        {!rightbtn && (
          <ToggleRightButton
            showWaitingRoom={true}
            totalChatsLength={totalChatsLength}
            chatsLength={chatsLength}
            handleRightBtn={handleRightBtn}
          />
        )}
      </div>
    </>
  );
}

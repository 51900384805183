import { useState, useEffect } from 'react';

export function useDevices() {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    const getDevices = () => navigator.mediaDevices.enumerateDevices().then(devices => setDevices(devices));
    getDevices();
    navigator.mediaDevices.addEventListener('devicechange', getDevices);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, []);

  return devices;
}

export function useAudioInputDevices() {
  const devices = useDevices();
  return devices.filter(device => device.kind === 'audioinput');
}

export function useVideoInputDevices() {
  const devices = useDevices();
  return devices.filter(device => device.kind === 'videoinput');
}

export function useAudioOutputDevices() {
  const devices = useDevices();
  return devices.filter(device => device.kind === 'audiooutput');
}

type MediaPermissions = {
  audio: boolean;
  video: boolean;
};
export function useMediaPermission() {
  const [state, setState] = useState<MediaPermissions>({
    audio: false,
    video: false,
  });

  useEffect(() => {
    checkPermissions();
  }, []);

  const checkPermissions = async () => {
    try {
      const cameraPermission = await navigator.permissions.query({ name: 'camera' });
      const audioPermission = await navigator.permissions.query({ name: 'microphone' });

      setState(({ ...o }) => {
        o.audio = audioPermission.state === 'granted';
        o.video = cameraPermission.state === 'granted';
        return o;
      });
      // const videoPermissions =  await navigator.mediaDevices.getUserMedia({audio: false, video: true});
    } catch (error) {
      console.log('Error : checkPermissions', error);
    }
  };

  return {
    cameraGratend: state.video,
    micGranted: state.audio,
  };
}

export function useHasAudioInputDevices() {
  const audioDevices = useAudioInputDevices();
  return audioDevices.length > 0;
}

export function useHasVideoInputDevices() {
  const videoDevices = useVideoInputDevices();
  return videoDevices.length > 0;
}

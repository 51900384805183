import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ChevronUp from '../../../icons/ChevronUp';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    height: '35px',
    minWidth: '80px',
    border: 'none',
    outline: 'none',
    fontSize: '18px',
    borderRadius: '10px',
    padding: '5px 10px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 9999,
  },
  active: {
    background: '#4283f3',
  },
  inactive: {
    background: '#323132',
  },
  leftBtn: {
    left: '2%',
    position: 'absolute',
    bottom: '2%',
    background: '#232325',
    zIndex: 9999,
    borderRadius: '10px',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  iconSpace: {
    paddingLeft: '20px',
    paddingTop: '5px',
  },
}));

export default function ToggleLeftButton({
  visitor,
  handleLeftBtn,
  active,
}: {
  visitor?: any;
  handleLeftBtn?: any;
  active?: any;
}) {
  const classes = useStyles();
  return (
    <div className={classes.leftBtn}>
      <button className={`${classes.button} ${active ? classes.active : classes.inactive}`} onClick={handleLeftBtn}>
        {visitor}{' '}
        <span className={classes.iconSpace}>
          {/* <KeyboardArrowUpIcon /> */}
          <ChevronUp />
        </span>
      </button>
    </div>
  );
}
